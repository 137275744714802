<template>
  <div style="background:#e4e6f1;">
    <div class="container">


    
<div class="row">

<div class="col-lg-8">

<div class=" display-3 ">Invoice ID: 5665000  <v-divider vertical>  </v-divider>  <v-spacer > </v-spacer>    </div> 
</div>



<div class="col-lg-4">

      <div class="text-lg-right mb-6">




        <v-btn
          elevation="9"
          rounded
          color="grey"
          @click="$router.push('/billing-center')"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          Back
        </v-btn>

    
      </div>
    
</div>

</div>
       


            <div class="row no-gutters">
        <div class="col-sm mr-1">
          <md-card class=" h-100" style="background:green;">
            <md-card-content>
              <div class="text-white">Total Invoice Due:</div>
              <div class="text-white display-2">$0.00 -  PAID IN FULL</div>
            </md-card-content>
          </md-card>
        </div>

        <div class="col-sm mr-1">
          <md-card class="bg-secondary h-100">
            <md-card-content>
              <div class="text-white">Amount Paid:</div>
              <div class="text-white display-2">$2500.00</div>
            </md-card-content>
          </md-card>
        </div>

        <div class="col-sm mr-1">
          <md-card class="bg-secondary h-100">
            <md-card-content>
              <div class="text-white">Total Account Balance:</div>
              <div class="text-white display-2">$2005.00</div>
            </md-card-content>
          </md-card>
        </div>

        <div class="col-sm">
          <md-card class="bg-secondary h-100">
            <md-card-content>
              <div class="text-white">Customer:</div>
              <div class="text-white display-2">Local Outfitters</div>
            </md-card-content>
          </md-card>
        </div>
      </div>


      <br>   


      <div class="main mt-2" style="background:#fff;min-height:700px;">
        <div class="container">


<div class="row">

<div class="col-lg-8">
      <div class="row ">
            <div class="col-lg-6">
           
<div class="display-3">
                Order ID: 34343 <br>
      

</div>


            </div>

         <div class="col-lg-6">
           
<div class="display-3">
               
                  PO ID: Johns order
      

</div>


            </div>
 

  </div>


            <div class="row mt-15 ">
            <div class="col-lg-6">
                     
                   <v-card-text>
                       <div class="display-2" >  Billing Address </div>
                       <v-divider>  </v-divider>
                  <p class="mb-2">
                    Local Outfitters Dock 2<br />
                    10004 Smith St<br />
                    dock 7<br />
                    bigtown NH 123456
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-semibold mr-2">Phone:</span> (123)
                    456-7890
                  </p>
                   </v-card-text>
              

            </div>
   <div class="col-lg-6">
            
                   <v-card-text>
                       <div class="display-2" >  Shipping Address </div>
                       <v-divider>  </v-divider>
                  <p class="mb-2">
                    Local Outfitters Dock 2<br />
                    10004 Smith St<br />
                    dock 7<br />
                    bigtown NH 123456
                  </p>
                  <p class="mb-2">
                    <span class="font-weight-semibold mr-2">Phone:</span> (123)
                    456-7890
                  </p>
                   </v-card-text>
             

            </div>


                </div>



</div>

<div class="col-lg-4">


 <v-card class="bg-secondary ">
                <v-card-text>

<div class="row">


 <div class="col-lg-6 text-white">

     Order Date
       <div class="display-2">   01-30-2021  </div>

    
 </div>
  <div class="col-lg-6 text-white">
       Due Date

     <div class="display-2">   02-30-2021  </div>

 </div>

</div>






                  <div class="text-white display-3 ">Total Due: $4025.00 <br /></div>
               
                
                <v-textarea
          name="input-7-1"
          label="Payment Note"
          background-color="white"
         
          hint="Hint text"
        ></v-textarea>

                   
                      <v-btn color="success"> Mark Paid </v-btn>
                   
                
                </v-card-text>
              </v-card>

</div>



</div>





         
          <div class="row ">
            <div class="col-lg-7">

               
           
      
              
         
             

            </div>
            <div class="col-lg-5">






             
            </div>
          </div>

          <div class="container">
  
            <!-- Start Items -->
            <div class="row ">
              <div class="col-lg-3">
                Invoice Items
              </div>
              <div class="col-lg-9">
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left display-1">
                          <strong> Line # </strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> Name </strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> Part # </strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> QTY </strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> Subtotal</strong>
                        </th>
                        <th
                          class="display-1"
                          style=" border:none;background:#fee5cd;"
                        >
                          <strong> Total </strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in orders" :key="item.id">
                        <td>{{ item.line }}</td>
                        <td>{{ item.name }}</td>
                        <td class="text-center">{{ item.sku }}</td>
                        <td class="text-center">15</td>
                        <td class="text-right">${{ item.id }}.00</td>

                        <td
                          class="text-right"
                          style=" border:none;background:#fee5cd;"
                        >
                          {{ item.total }}
                        </td>
                      </tr>

                      <tr style="background:#eeeae8; border:none;">
                        <td style=" border:none;"></td>
                        <td style=" border:none;"></td>
                        <td></td>
                        <td class="text-right" style=" border:none;">
                          Sub-Total
                        </td>
                        <td class="text-right" style=" border:none;">
                          $564.00
                        </td>
                        <td
                          class="text-right"
                          style=" border:none;background:#eabd92;"
                        >
                          $564.00
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>

            <!-- End Items -->

            <!-- Start Payments -->
            <div class="row ">
              <div class="col-lg-3">
                Invoice Payments
              </div>
              <div class="col-lg-9">
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                       
                        <th class="text-left display-1">
                          <strong> Method </strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> Received</strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> Note </strong>
                        </th>
                        <th class="text-left display-1">
                          <strong> Amount</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in payments" :key="item.id">
                        
                        <td>{{ item.method }}</td>
                        <td>{{ item.date }}</td>
                        <td>{{ item.note }}</td>

                        <td style=" border:none;">{{ item.total }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>

            <!-- End payments -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    selected: [],
    items: [
      {
        action: "$5,000.00",
        headline: "$45000.00",
        subtitle: "2 invoices",
        sku: "5652",
        title: "Discount Bikes"
      },
      {
        action: "$5600.00",
        headline: "$533.45",
        subtitle: "1 invoice",
        title: "Best Clothing"
      },
      {
        action: "$4500",
        headline: "$4543.45",
        subtitle: "4 invoices",
        title: "Sandra Adams"
      },
      {
        action: "$0.00",
        headline: "$2553.45",
        subtitle: "5 invoices",
        title: "New Supplier 2"
      },
      {
        action: "$0.0",
        headline: "$533.45",
        subtitle: "12 invoices",
        title: "Hat Maker 7"
      }
    ],

    orders: [
      {
        id: "1001",
        line: "1",
        invoiceid: "20000",
        name: "Green Bikes Small",
        user: "Tom Smith",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Shipped",
        sku: "56438"
      },
      {
        id: "102",
        invoiceid: "204400",
        line: "2",
        name: "XL  Purple Shirt",
        user: "Anne Clarke",
        date: "12-10-2020",
        total: "$14,000.00",
        status: "Shipped",
        sku: "5652"
      }
    ],

    payments: [
      {
        id: "1001",
        line: "1",
        invoiceid: "20000",
        method: "Manual Net Terms",
        user: "Tom Smith",
        date: "12-10-2020",
        total: "$150.00",
        note: "paid via check #45",
        sku: "56438"
      },
  
    ]
  }),

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  }
};
</script>

<style>
table,
th,
td {
  border: 0.5px solid #eee;
  border-collapse: collapse;
}

th,
td {
  padding: 20px;
}

th {
  text-align: left;
}
</style>
